<template>
  <div class="fake_content"></div>
</template>

<script>
</script>

<style lang="scss" scoped>
.fake_content{
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../../public/img/page/desktop.png);
  @media screen and (max-width: 599px) and (orientation: portrait),(max-height: 500px) and (orientation: landscape){
    background-image: url(../../public/img/page/phone.png);
    // top: 44px;
    // bottom: 64px;
  }
}
</style>